<template>
  <c-box mb="28px" max-w="100%">
    <c-box
      background="#FFFFFF"
      border="1px solid #F2F2F2"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
      border-radius="12px"
      class="container"
    >
      <c-flex py="25px" px="27px" border-bottom="1px solid #C4C4C4">
        <c-flex justify="space-between" align="center" flex-grow="1">
          <c-heading
            as="h3"
            font-family="Montserrat"
            font-weight="600"
            font-size="16px"
            line-height="24px"
          >
            Nutritionists Tracking
          </c-heading>
        </c-flex>
      </c-flex>

      <c-box max-width="892px" overflow-x="auto">
        <c-grid
          w="100%"
          :template-columns="'repeat(' + headings.length + ', 0fr)'"
        >
          <c-box
            v-for="(item, index) in headings"
            :key="'heading-' + index"
            :w="item.width"
            py="11px"
            px="14px"
            color="brand.900"
            font-weight="bold"
            font-size="12px"
            line-height="18px"
            textTransform="uppercase"
            bg="rgba(0, 140, 129, 0.1)"
            display="flex"
            align-items="center"
          >
            {{ item.label }}
          </c-box>
        </c-grid>

        <c-grid
          w="100%"
          max-w="100%"
          :template-columns="'repeat(' + headings.length + ', 0fr)'"
          v-for="(item, idx) in items"
          :key="idx"
        >
          <c-box
            :w="headings[0].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            border-bottom="1px solid #C4C4C4"
          >
            {{ numberingRow(idx) }}
          </c-box>
          <c-box
            :w="headings[1].width"
            py="16px"
            px="20px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            border-bottom="1px solid #C4C4C4"
          >
            {{ item.nutritionistName }}
          </c-box>
          <c-box
            :w="headings[2].width"
            py="16px"
            px="20px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            border-bottom="1px solid #C4C4C4"
          >
            {{ item.clientName }}
          </c-box>
          <c-box
            :w="headings[3].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            border-bottom="1px solid #C4C4C4"
          >
            {{ item.duration }} Hari
          </c-box>
          <c-box
            :w="headings[4].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            border-bottom="1px solid #C4C4C4"
          >
            {{ getFormatDate(item.adimeNotes) }}
          </c-box>
          <c-box
            :w="headings[5].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            textTransform="capitalize"
            border-bottom="1px solid #C4C4C4"
          >
            {{ getFormatDate(item.mealPlan) }}
          </c-box>
          <c-box
            :w="headings[6].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            border-bottom="1px solid #C4C4C4"
          >
            {{ getFormatDate(item.followUpNotesPosting) }}
          </c-box>
          <c-box
            :w="headings[7].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            border-bottom="1px solid #C4C4C4"
          >
            {{ getFormatDate(item.dietFeedbackPosting) }}
          </c-box>
        </c-grid>
      </c-box>

      <c-flex v-if="!isEmpty" justify="space-between" py="28px" px="40px">
        <c-flex align="center">
          <c-text color="gray.900" fontSize="14px" lineHeight="21px">
            Show:
          </c-text>
          <c-select
            v-model="perPage"
            min-w="120px"
            border-radius="6px"
            fontSize="14px"
            ml="13px"
            size="md"
          >
            <option value="5">5 Rows</option>
            <option value="10">10 Rows</option>
            <option value="15">15 Rows</option>
            <option value="20">20 Rows</option>
          </c-select>
        </c-flex>

        <c-flex justifyContent="flex-end" w="100%">
          <Pagination
            :totalPages="totalPages"
            :perPage="parseInt(this.perPage)"
            :currentPage="currentPage"
            :maxVisibleButtons="3"
            @pagechanged="onChangePage"
          />
        </c-flex>
      </c-flex>
    </c-box>
  </c-box>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import generalMixin from "@/utils/general-mixins";
import Pagination from "@/components/pagination";
export default {
  name: "DashboardNutritionistTracking",
  mixins: [generalMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      headings: [
        { label: "no", width: "74px" },
        { label: "nama ahli gizi", width: "182px" },
        { label: "nama klien", width: "182px" },
        { label: "duration", width: "113px" },
        { label: "adime notes", width: "118px" },
        { label: "meal plan created", width: "135px" },
        { label: "follow-up notes posting", width: "135px" },
        { label: "diet feedback posting", width: "135px" },
      ],
      currentPage: 1,
      perPage: "5",
    };
  },
  computed: {
    ...mapState({
      items: (s) => s.admDashboard.nutritionists,
      meta: (s) => s.admDashboard.nutritionistMeta,
    }),
    isEmpty() {
      return this.items.length === 0;
    },
    totalPages() {
      return Math.ceil(this.meta?.total / parseInt(this.perPage));
    },
  },
  watch: {
    perPage: {
      handler(val) {
        this.loadNutritionistsTracking({
          page: this.currentPage,
          perpage: parseInt(val),
        });
      },
    },
  },
  mounted() {
    this.loadNutritionistsTracking({
      page: this.currentPage,
      perpage: parseInt(this.perPage),
    });
  },
  methods: {
    ...mapActions({
      loadNutritionistsTracking: "admDashboard/listNutritionistsTracking",
    }),
    getFormatDate(date) {
      if (!date) return "-";
      moment.locale("id");
      return moment(date).format("D MMM yyyy");
    },
    numberingRow(index) {
      return this.currentPage * this.perPage - this.perPage + index + 1;
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadNutritionistsTracking({
        page: this.currentPage,
        perpage: parseInt(this.perPage),
      });
    },
  },
};
</script>